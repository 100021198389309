import React from 'react';
import ParentPage from '../components/ParentPage';
import './../App.css';

const Queens = () => {
  return (
    <ParentPage sex={'female'} />
  );
};

export default Queens;