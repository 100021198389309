import './../App.css';

const About = () => {
    return (
      <div className='page-background paragraph-container'>
        <h1 className='page-header padded-header'>JungleBeauty Bengals: vivid piece of jungle beauty in your home</h1>
        <p>
          Junglebeauty is a TICA REGISTERED in-home cattery located in the KW area, Ontario, Canada. We focus on high quality brown spotted Bengals 
          with three distinct colours and on exceptional silver spotted Bengals. Two colours of small and medium sized rosettes give the cat a wild-like 
          look resembling wild spotted cats: jaguars, snow leopards and clouded leopards.
        </p>
        <p>
          Currently we have queens from light brown and golden to charcoal colour that were born in Ontario or Quebec, Canada. We also have 
          exceptionally high quality silver Queens. Our king Churchil is a TICA champion bloodline Bengal cat stud.
        </p>
        <p>
          The health of our cats and kittens is our top priority, so we do our best to support and develop their strong immune systems. Kittens 
          are socialized living and interacting with people and other pets (cats, parrot and dog) in a non-caged environment. All of them are 
          highly people-oriented and affectionate. Kittens are usually released at the age of 11-14 weeks when they feel comfortable to live 
          without their mom and are ready to explore the world. They exercise a lot playing on cat trees and cat pipes, jumping, running and climbing.
          We even have an exercise wheel for adult cats to walk and run!
        </p>
      </div>
    );
  };

export default About;