import React from 'react';
import ParentPage from '../components/ParentPage';
import './../App.css';

const Kings = () => {
  return (
    <ParentPage sex={'male'} />
  );
};

export default Kings;