import './../App.css';

const AboutBengals = () => {
    return (
        <>
            <div className='page-background paragraph-container'>
                <h1 className='page-header padded-header'>Specific Features of Bengal Cats</h1>
                <p>
                    The Bengal is a domestic cat breed created by breeding an Asian leopard cat with a domestic cat. The result is a highly intelligent, energetic feline with a striking coat pattern. 
                    Here are ten specific features of Bengals that make them a unique and fascinating breed and what we see every day watching our Bengal kittens and cats in our Junglebeauty Cattery:
                </p>
                
                <ul className='list-container'>
                    <li>
                        <b>COAT:</b> Bengals have distinctive coats covered in spots, rosettes or marbled patterns in shades of brown, gold, orange, silver and snow that resemble a wild leopard. It gives 
                        them an exotic, wild look that will turn heads. The fur of every Bengal kitten is also very short, dense, silky and incredibly soft, making them very pleasant to pet.
                    </li>
                    <li>
                        <b>ATHLETICISM:</b> Bengals are incredibly athletic and love to climb, jump, and play. Every Bengal kitten is an excellent jumper and can reach impressive heights.
                    </li>
                    <li>
                        <b>INTELLIGENCE:</b> Bengals are very intelligent cats that are easy to train. They can learn to do tricks and are very good at problem-solving. They are also very curious and love
                        to explore everything around them.
                    </li>
                    <li>
                        <b>ENERGY:</b> Bengal cats have a lot of energy and require plenty of exercise and stimulation. They are not lap cats and prefer to play and explore.
                    </li>
                    <li>
                        <b>SOCIABILITY:</b> Bengals are social cats and enjoy the company of their human family. They are affectionate and love to be near their humans, follow their owners in many 
                        activities and enjoy participating in events. They are also very social and get along well with other cats and dogs.
                    </li>
                    <li>
                        <b>VOCALIZATION:</b> Bengals are talkative cats known for their unique vocalizations. They will communicate with their humans and make a variety of noises.
                    </li>
                    <li>
                        <b>WATER-LOVING:</b> Bengals are unique in that they love water. They often play in the water and may join their humans in the shower or bath.
                    </li>
                    <li>
                        <b>HUNTING INSTINCTS:</b> Bengals have a strong hunting instinct and enjoy playing with toys that mimic prey. They may also try to hunt small animals in the home.
                    </li>
                    <li>
                        <b>HEALTH:</b> Bengals are generally healthy cats with a lifespan of 12-16 years. They have a strong immune system and a robust digestive system.
                    </li>
                    <li>
                        <b>PERSONALITY:</b> Finally, Bengal cats and kittens have unique and playful personality that makes them a joy to be around. They are curious, adventurous, and always up for a good game.
                    </li>
                </ul>
                <h2 className='page-subheader'>Intelligence</h2>
                <p>
                    Bengals are a highly intelligent breed of cat that are known for their quick learning and curious nature. They are very active and playful cats that need plenty of mental 
                    stimulation, and their intelligence makes them a joy to be around.
                </p>
                <p>
                    Here are a few of the key features of Bengals' intelligence:
                </p>
                <ul className='list-container'>
                    <li>
                        Problem-solving: Every Bengal kitten and cat is very good at problem-solving and can figure out how to get what they want. Bengal cats will often investigate things that interest 
                        them and try to find a way to get to them. This makes them very curious and inquisitive cats that are always exploring their environment.
                    </li>
                    <li>
                        Trainability: Bengal kittens are very easy to train and can learn a wide range of tricks and commands. They are very responsive to positive reinforcement and can quickly learn what 
                        behaviours are rewarded. This makes them a joy to train and work with.
                    </li>
                    <li>
                        Adaptability: Bengals are very adaptable cats that can quickly adjust to new environments and situations. They are very curious and love to explore their surroundings, which makes 
                        them very adaptable to new situations. This makes them great pets for people who travel frequently or have a changing lifestyle.
                    </li>
                    <li>
                        Communication: Bengal cat and kittens are very good communicators and can understand human language and body language. They can also communicate with other cats and animals through 
                        vocalizations and body language. This makes them very social and easy to communicate with.
                    </li>
                    <li>
                        Curiosity: Bengals are very curious cats that love to explore their environment. They are always investigating new things and trying to figure out how things work. This makes them 
                        very intelligent cats that are always learning and growing.
                    </li>
                </ul>
                <h2 className='page-subheader'>Vocalization</h2>
                <p>
                    Bengals are known for their vocalization skills and use a wide range of sounds to communicate with their owners and other cats. They are very social cats that enjoy interacting with 
                    their owners and other pets, and their vocalization skills make them easy to understand.
                </p>
                <p>
                    Here are a few of the key features of Bengals' vocalization skills:
                </p>
                <ul className='list-container'>
                    <li>
                        Meowing: Bengal cats and kittens meow to communicate with their owners and other cats. They use different types of meows to express different emotions and needs. For example, a 
                        short meow might indicate a greeting, while a long meow might indicate hunger or a desire for attention.
                    </li>
                    <li>
                        Purring: Almost every Bengal kitten or cat purrs when they are happy or relaxed. Purring is a sign of contentment and is often associated with cats that feel safe and loved.
                    </li>
                    <li>
                        Chirping: Bengals also chirp, a unique sound similar to a bird's chirp. This sound is often used to express excitement or curiosity.
                    </li>
                    <li>
                        Growling: Some Bengal cat and kittens growl when they feel threatened or afraid. This is a warning sign that they are not feeling safe and that they may become aggressive if they 
                        feel they need to defend themselves.
                    </li>
                    <li>
                        Screaming: Bengals have a unique scream that is very loud and can be heard from a distance. This sound is often used when they are in distress or feeling threatened.
                    </li>
                </ul>
            </div>    
            <div className='page-background'>
                <h1 className='page-header padded-header'>History of the Bengal Breed</h1>
                <p>
                    There are more than 30 different breeds of domestic cats, plus many mixes of those breeds. Among those mixed breeds are several designer, or hybrid, cats. Breeders create
                    designer cats to look like a wildcat but have the personality of a domestic cat.
                </p>
                <p>
                    One of the most popular designer cats is the Bengal cat. It is cross between a wildcat called the Asian leopard cat and a domestic house cat. Bengal kittens and cats have several 
                    specific features. The name Bengal comes from the Asian leopard cat’s scientific name, Felis bengalensis, because they were discovered in the Bay of Bengal in Asia. 
                </p>
                <h2 className='page-subheader'>Asian Leopard Cat</h2>
                <p>
                    Asian leopard cats are native to southern Asia. They are found from southern India eastward through Thailand, Malaysia and into China. In China they are called "money cats" because their 
                    spots look like Chinese coins.
                </p>
                <p>
                    Asian leopard cats often live near water in jungles, brush, forests, or plains. They are shy and skilled nocturnal hunters. Their diet includes rodents and small birds, occasionally 
                    venturing into farmyards to prey on domestic chickens.
                </p>
                <p>
                    These small wildcats weigh around 10-12 pounds. They have longer legs and bodies then domestic cats. To see better at night, they have large eyes. Most Asian leopard cats are tan 
                    to orange colored and look like pint-sized leopard. Their coats are marked with stripes, solid spots, and doughnut-holed spots called rosettes. Jaguars wear rosettes, too. They have 
                    dark spots and a white belly.
                </p>
                <p>
                    Asian leopard cats are excellent swimmers and climbers. Mrs. Mill writes: “Assumed to be vicious by people who have never encountered one, ALCs instead are afraid of humans. For 
                    countless centuries they have been hunted with traps and spears. … It is not in the nature of the tiny ALC TO ATTACK WHEN UNPROVOKED. They just want to be left alone. “Wild” does 
                    not mean vicious… They need not be feared if allowed their privacy. And their hybrid offspring are not more dangerous nor unpredictable than they are.”
                </p>
                <h2 className='page-subheader'>A New Mix</h2>
                <p>
                    The Bengal cat is a relatively new hybrid breed of cat which exhibits the “wild” markings (such as large spots, rosettes and a light/white belly), and body structure reminiscent of 
                    the wild Asian Leopard Cat.
                </p>
                <p>
                    Bengal cats first became popular because they have a desirable “wild” appearances with a gentle domestic cat temperament, provided by at least four generations from the original crossing 
                    between a domestic feline and an Asian Leopard Cat. Several domestic cat breeds have been ancestors of the Bengal cat. These include the Egyptian mau, the Abyssinian and the Burmese.
                </p>
                <p>
                    The name Bengal was derived from the name of the Asian Leopard Cat (ALC) and not from the more widely known Bengal tiger species, which is unrelated to the Bengal’s ancestry.
                </p>
                <p>
                    The first mention of a cross between an Asian Leopard Cat and a domestic cat was in 1889. Harrison Weir wrote in “Our Cats and All About Them”: “There is a rich-coloured brown tabby 
                    hybrid to be seen at the Zoological Society Gardens in Regent’s Park, between the wild cat of Bengal and tabby she-cat. It is handsome, but very wild. These hybrids, I am told, will 
                    breed again with tame variety, or with others.” The mention of a confirmed ALC/domestic cross was in 1934 in a Belgian scientific journal, and in 1941 a Japanese cat publication printed 
                    an article about one that was kept as a pet. 
                </p>
                <h2 className='page-subheader'>Birth of Bengals</h2>
                <p>
                    Credit for the modern Bengal cat breed goes to Jean Sudgen Mill.
                </p>
                <p>
                    She submitted a term paper for her genetics class at UC Davis on the subject of cross breeding cats in 1946. Mill had accomplished this as part of her studies and did not realize that 
                    she had just set the foundation for the development of a new breed of cat.
                </p>
                <p>
                    She had acquired an Asian Leopard Cat in the late 1950’s. In 1963, she crossed this wild female Leopard Cat with a shorthaired, solid black domestic tomcat. A spotted daughter from that 
                    mating, called Kiki, grew up and had kittens of her own, more spotted and solid offspring. However, the line was discontinued in 1966 when Mill had to give up breeding cats.
                </p>
                <p>
                    The 1960s was a period when many well known breeders, including Jean Sugden, produced ALC/domestic crosses, but records indicate that none of them took it past the F2 stage. Several zoos 
                    in Europe also produced a number of F1 ALC crosses. During this period there was an epidemic of feline leukemia virus and it became known that many wild cats seemed to have a natural 
                    immunity to the disease. As a result, Loyola University would start a research program in the 1970s to investigate if this natural immunity could be bred in or replicated.
                </p>
                <p>
                    Throughout the 1960s and 1970s there was a great deal of activity with hybrids, but there was no sufficient effort to create an actual breed from them. A number of Cat Clubs formed that 
                    focused on hybrids. Club newsletters detailing the production of Bengals and Safaris started being published and members of these clubs bred some second and third generation Bengals. 
                    These were registered with the America Cat Fancies Association (ACFA) in 1977 as experimental and were shown at several ACFA cat shows throughout the 1970s
                </p>
                <p>
                    Around this time, Jean Mill resurfaced again, and the following quote explains her increased interest in renewing her breeding efforts. “ … I deliberately crossed leopard cats with 
                    domestic cats for several important reasons. At that time, wild cats have been exploited for the fur market. Nursing female leopard cats defending their nests were shot for their pelts, 
                    and the cubs were shipped off to pet stores worldwide. Unsuspecting cat lovers brought them, unaware of the danger, their unpleasant elimination habits and the unsuitability of keeping 
                    wild cats as pets. Most of the wild kittens from this era ended up in zoos or escaped onto city streets. I hoped that by putting a leopard coat on a domestic cat, the pet trade could be 
                    safely satisfied. If fashionable women could be dissuaded from wearing furs that look like friend’s pet, the diminished demand would result in less poaching of wild species.”
                </p>
                <p>
                    She contacted Dr. Willard Centerwall in Riverside who had produced a number of F1s using domestic tabbies at Loma Linda Medical Center. In the late 1960’s, Dr. Willard Centerwall, a 
                    professor of pediatrics and of maternal and child health, studied feline genetics as a scientific hobby. Although Centerwall published several studies of small wild cats, his work 
                    with Asian Leopard Cats intersected with research on immunocompromised individuals. That project was built on a foundation of comparative gene mapping of humans and cats. Centerwall was 
                    particularly interested in the Asian Leopard Cat’s lack of a gene for contracting feline leukemia. Centerwall was curious if the native immunity of the ALC to leukemia could be passed 
                    on to hybrid offspring. To conduct his tests, Centerwall only needed to collect blood from the hybrid cats. The animals then required homes.
                </p>
                <p>
                    In 1980, Mill met Centerwall and adopted several of his female hybrids from his Leopard Cat and domestic shorthair crosses, and was able to continue her interrupted efforts to “put the 
                    gorgeous coat on a new, domestic breed of cat”. It was determined that the males from these first generations were always sterile. Fortunately, ALC/domestic cat crosses are fertile in 
                    the female sex. Mill wrote: “It was then necessary to breed them to another friendly domestic tomcat, thereby diluting the wild inheritance by another half.” Second-generation kittens were 
                    much less shy and were considerably more outgoing and friendly. Most of the males, too, proved after many trials also to be sterile.
                </p>
                <p>
                    Jean Mill decided to not use local domestics to create her first Bengals. She felt the Asian Leopard Cat was a genetically superior animal and wished to avoid weakening this element. 
                    Around 1982, the Mills made a trip to India where a zoo curator showed them a feral Indian Mau, having fantastic glittered orange coat with deep brown rosettes. This was how the famous 
                    rosetted domestic cat called “Millwood Tory of Delhi” came to be found in virtually all Bengal pedigrees. Over the years, Mrs. Mill combined her hybrids with other breeds, including the 
                    Abyssinian, Bombay, British shorthair, and the Ocicat. From this unlikely group of cats came the Bengal. Mill worked tirelessly to see the Bengal breed become fully domesticated. She is 
                    rightly considered to be the true originator of the Bengal cat.
                </p>
                <h2 className='page-subheader'>Recognition of Bengals</h2>
                <p>
                    In 1983, Mrs. Mill registered these cats with The International Cat Association, TICA, as well as their offspring by a brown spotted domestic tabby, Millwood Finally Found.
                </p>
                <p>
                    Millwood Penny Ante, a second-generation kitten and other early kittens, were first exhibited at the TICA cat show in 1985, showing with exhibition-only 
                    status. The public reaction was overwhelming, as crowds gathered in awe over this magnificent new creation.
                </p>
                <p>
                    Soon afterward, Bengals were permitted to compete in the New Breed and Color classes where they were handled by the judges. A standard was written to describe in detail the features to 
                    be rewarded in the show rings, and those to be penalized. The new Bengal cat’s breed had been born! It was not until 1992, however, that TICA admitted the breed into Championship status.
                </p>
                <p>
                    Other breeders were establishing their bloodlines, and registering them with TICA. In the 1980s, Dr. Gregg and Elizabeth Kent, who had been hybridizing the Asian Leopard Cat for a 
                    number of years, used an Egyptian Mau queen with a male Leopard Cat. They were so successful that some pedigreed Bengals today still trace their origin to the Kents' cats. Another 
                    breeder who made notable contribution to the breed was Ethel Hauser. There are at present, other Leopard Cats being used in various breeding programs. This will aid significantly in 
                    expanding the limited gene pool which now exists.
                </p>
                <h2 className='page-subheader'>Sources</h2>
                <ul className='list-container'>
                    <li>
                        Jean S. Mill. The Guide to Owning a Bengal Cat. 2002
                    </li>
                    <li>
                        Jill C. Wheeler. Bengal Cats. ABDO Publishing Company. 2011
                    </li>
                    <li>
                        Lynn M. Stone. Bengal Cats. The Rourke Corporation, Inc. 1999.
                    </li>
                    <li>
                        Gene Johnson. Getting to know The Bengal Cat. 1993.
                    </li>
                    <li>
                        Taylor David. Bengal Cats and Kittens. 2013.
                    </li>
                </ul>
            </div>    
        </>
    );
  };

export default AboutBengals;